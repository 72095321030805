import * as React from 'react';
import { LookupEditor } from './LookupEditor';
import { AcFunctionEditor } from './AcFunctionEditor';
import { QuantityLimitEditor } from './QuantityLimitEditor';
import { UnAndPsnPredicateEditor } from './UnAndPsnEditor';

declare namespace PredicateEditor {
    export interface Props {
        predicate: RulePredicate;
        predicateType: PredicateType;
        isNew: boolean;
        onCancel: noop;
        onSave: (predicate: RulePredicate) => void;
    }

    export interface State {
        editorOnSave: EditorOnSave;
    }
}

export class PredicateEditor extends React.Component<PredicateEditor.Props, PredicateEditor.State> {

    constructor(props: PredicateEditor.Props, context: any) {
        super(props, context)

        this.state = {
            editorOnSave: null
        };

        this.setOnSave = this.setOnSave.bind(this);
    }

    render() {
        const { onCancel, isNew } = this.props;

        return (
            <div>
                <header className="section-header">
                    <h4>
                        { isNew ? "Add" : "Edit" } { name }
                    </h4>
                </header>
                {
                    this.renderEditor()
                }
                <div className="form-buttons--justified">
                    <button className="btn btn-secondary btn-sm" type="button" onClick={ onCancel }>Cancel</button>
                    <button className="btn btn-primary btn-sm" type="button" onClick={ () => this.onSaveClick() }>Save</button>
                </div>
            </div>
        );
    }

    renderEditor() {

        const { predicate, predicateType, isNew } = this.props;

        const predicateData = isNew ? {} as PredicateData : predicate.data;

        switch (predicateType.meta.editorType) {
            case 'QuantityLimitEditor':
                return (
                    <QuantityLimitEditor
                        editorData={ predicateType.meta }
                        predicateData={ predicateData as QuantityLimitPredicateData }
                        setOnSave={ this.setOnSave }
                        isNew={ isNew }
                        />
                );
              case 'UnAndPsnPredicateEditorMeta':
                  return (
                      <UnAndPsnPredicateEditor
                          editorData={ predicateType.meta }
                          predicateData={ predicateData as UnAndPsnPredicateData }
                          setOnSave={ this.setOnSave }
                          isNew={ isNew }
                          />
                  );
            case 'LookupEditor':
                return (
                    <LookupEditor
                        lookupPlaceholder={ predicateType.name }
                        editorData={ predicateType.meta }
                        predicateData={ predicateData as LookupPredicateData }
                        setOnSave={ this.setOnSave }
                        />
                );
            case 'AcFunctionEditor':
                return (
                    <AcFunctionEditor
                        lookupPlaceholder={ predicateType.name }
                        editorData={ predicateType.meta }
                        predicateData={ predicateData as AcFunctionPredicateData }
                        setOnSave={ this.setOnSave }
                        />
                );
        }

        return (
            <p>
                No editor has been added for the editor type.
            </p>
        );
    }

    setOnSave(editorOnSave: EditorOnSave) : void {
        this.setState({
            editorOnSave: editorOnSave
        });
    }

    onSaveClick() {
        const { predicate, predicateType, isNew, onSave } = this.props;
        const { editorOnSave } = this.state;

        var saveData = editorOnSave();
        const { isValid, summary, predicateData } = saveData;

        if (!isValid) {
            return;
        }

        if (isNew) {
            onSave({
                id: 'new',
                type: predicateType.type,
                data: predicateData,
                summary: summary
            });
        } else {
            onSave({
                ...predicate,
                data: predicateData,
                summary: summary
            });
        }
    }
}
