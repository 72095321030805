import * as React from 'react';
import { ErrorMessageHelper, ErrorMessageMap, FormGroup, LookupComponent } from 'library/form/components';
import { useState } from 'react';

type ProperShippingNameProps = {
  predicateData: UnAndPsnPredicateData;
  editorData: UnAndPsnEditorMeta;
  setOnSave: EditorSetOnSave;
  isNew: boolean;
}

export const validate = (data: UnAndPsnPredicateData): ErrorMessageMap => {
  const errorHelper = new ErrorMessageHelper<UnAndPsnPredicateData>();

  if (!data.inputSource) {
      errorHelper.addErrorFor('inputSource', 'Input Source is required');
  }

  if (!data.textComparer) {
    errorHelper.addErrorFor('textComparer', 'Match By is required');
  }


  if (!data.text) {
      errorHelper.addErrorFor('text', 'Name is required');
  }

  return errorHelper.errors;
};

const getSummary = (data: UnAndPsnPredicateData, editorData: UnAndPsnEditorMeta): string => {
  const parts: string[] = [];

  if (data.unNumbers.length > 0) {
    parts.push(`${data.unNumbers.join(', ')} and`);
  }

  if (editorData.inputSources && editorData.inputSources.length > 0) {
    var selectedSource = editorData.inputSources.find(w => w.value == data.inputSource);
    if (selectedSource) {
      parts.push(selectedSource.label);
    }
  }

  if (data.text) {
    var match = editorData.textComparerOptions.find(w => w.value == data.textComparer);

    parts.push(`${match.label} "${data.text}"`);
  }

  return parts.join(' ');
};

export function UnAndPsnPredicateEditor(props: ProperShippingNameProps) {

    const { editorData, isNew } = props;
    const [formErrors, setFormErrors] = useState<ErrorMessageMap>({});
    const [ data, setData ] = useState<UnAndPsnPredicateData>(
      isNew
      ? {
        dataType: 'UnAndPsnPredicate',
        unNumbers: [],
        inputSource: '',
        textComparer: '',
        text: '',
        ignoreCase: false,
        failMessage: ''
      }
      : props.predicateData);

    React.useEffect(() => {
      props.setOnSave(() => {
          const errors = validate(data);
          setFormErrors(errors);

          return {
              predicateData: data,
              summary: getSummary(data, editorData),
              isValid: Object.keys(errors).length === 0
          };
      });
    }, [data]);

    const errorHelper = new ErrorMessageHelper<UnAndPsnPredicateData>(formErrors);

    return (
        <div>
            <LookupComponent
                    placeholder={ 'Search by UN' }
                    lookupUrl={ editorData.unLookupUrl}
                    currentValues={ data.unNumbers.map(un => ({ value: un, label: un })) }
                    onChange={ (values) => setData({...data, unNumbers: values.map(item => item.value) }) }
                    label="UN Number(s)"
                    minSearchChars={ editorData.unMinSearchChars }
                    validationMessages={ errorHelper.errorsForKey('unNumbers') }
                    />

            <FormGroup label="Match To" required labelFor="input-source" additionalWrapper="select-wrapper" validationMessages={ errorHelper.errorsFor('inputSource') }>
                <select className="form-control" onChange={ (e: React.ChangeEvent<HTMLSelectElement>) => setData({...data, inputSource: e.target.value}) } id="input-source" value={ data.inputSource }>
                    <option key="default" value="">Please select an option</option>
                    {
                        editorData.inputSources.map(opt =>
                            <option value={ opt.value }
                                key={ opt.value }>
                                { opt.label }
                            </option>
                        )
                    }
                </select>
            </FormGroup>

            <FormGroup label="Match By" required labelFor="match-name-by" additionalWrapper="select-wrapper" validationMessages={ errorHelper.errorsFor('textComparer') }>
                <select className="form-control" onChange={ (e: React.ChangeEvent<HTMLSelectElement>) => setData({...data, textComparer: e.target.value}) } id="text-comparer" value={ data.textComparer }>
                    <option key="default" value="">Select how to match the name</option>
                    {
                        editorData.textComparerOptions.map(opt =>
                            <option value={ opt.value }
                                key={ opt.value }>
                                { opt.label }
                            </option>
                        )
                    }
                </select>
                <label>
                <input type="checkbox" name="ignore-case"
                    value="true"
                    checked={ data.ignoreCase }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setData({ ...data, ignoreCase: event.target.checked })}
                    />
                  &nbsp;Ignore case
                  </label>
            </FormGroup>

            <FormGroup
                label="Text" required
                labelFor="text" validationMessages={ errorHelper.errorsFor('text') }>
                <span className="form-input input-wrapper">
                  <textarea
                      name="text"
                      className="form-control"
                      onChange={ (e: React.ChangeEvent<HTMLTextAreaElement>) => setData({...data, text: e.target.value }) }
                      value={ data.text }
                      />
                </span>
            </FormGroup>

            <FormGroup label="Fail message (Override)" labelFor="fail-message">
                <textarea
                    name="fail-message"
                    id="fail-message"
                    className="form-control"
                    value={ data.failMessage }
                    onChange={ (e: React.ChangeEvent<HTMLTextAreaElement>) => setData({...data, failMessage: e.target.value }) } />
            </FormGroup>
          </div>
      );
}
